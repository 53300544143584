import * as React from 'react';
import styled from 'styled-components';
import {
  color,
  ColorProps,
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import { nanoid } from 'nanoid';

export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
    variant?: 'red' | 'blue';
  }
>(({ title, variant, ...props }, svgRef) => {
  const [titleId] = React.useState(() => (title ? nanoid() : undefined));
  const [startColor, endColor] =
    variant === 'red' ? ['#D14242', '#F18989'] : ['#3DCBF5', '#0DA6F2'];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M21.5 9.22656C21.5 7.1498 19.9599 5.42854 17.9699 5.16113C17.7053 3.37477 16.1723 2 14.3265 2C14.3204 2 14.3143 2 14.3081 2.00007C13.3506 2.00497 12.5817 2.79954 12.5817 3.76417V6.14066C12.5817 6.46424 12.3213 6.72662 12 6.72662C11.6788 6.72662 11.4183 6.4643 11.4183 6.14066V3.76417C11.4183 2.7996 10.6494 2.00497 9.69192 2.00007C9.68574 2.00007 9.67963 2 9.67345 2C7.82771 2 6.29469 3.37477 6.03016 5.16113C4.04012 5.42861 2.5 7.1498 2.5 9.22656C2.5 10.2684 2.88529 11.2483 3.57482 12C2.88523 12.7517 2.5 13.7315 2.5 14.7734C2.5 16.8502 4.04005 18.5715 6.03016 18.8389C6.29469 20.6252 7.82777 22 9.67345 22C9.67963 22 9.68574 22 9.69192 21.9999C10.6494 21.995 11.4183 21.2005 11.4183 20.2358V17.8593C11.4183 17.5358 11.6787 17.2734 12 17.2734C12.3212 17.2734 12.5817 17.5357 12.5817 17.8593V20.2358C12.5817 21.2004 13.3506 21.995 14.3081 21.9999C14.3143 21.9999 14.3204 22 14.3265 22C16.1723 22 17.7053 20.6252 17.9699 18.8389C19.9599 18.5714 21.5 16.8502 21.5 14.7734C21.5 13.7316 21.1147 12.7517 20.4252 12C21.1147 11.2483 21.5 10.2684 21.5 9.22656Z"
        fill="url(#paint0_linear_10560_126354)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7303 8.61509C12.9933 8.81336 13.0543 9.19911 12.8664 9.47668L11.5766 11.3824H13.2574C13.8603 11.3824 14.2111 12.1016 13.8606 12.6194L12.0861 15.2413C11.8982 15.5189 11.5327 15.5832 11.2697 15.3849C11.0067 15.1866 10.9457 14.8009 11.1336 14.5233L12.4234 12.6176H10.7426C10.1397 12.6176 9.78892 11.8984 10.1394 11.3806L11.9139 8.75869C12.1018 8.48112 12.4673 8.41683 12.7303 8.61509Z"
        fill="url(#paint1_linear_10560_126354)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10560_126354"
          x1="18.9767"
          y1="20.2571"
          x2="8.1366"
          y2="2.8672"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10560_126354"
          x1="10.75"
          y1="8.93749"
          x2="17.3353"
          y2="15.3707"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
});
export const IconSystemV2CourseColorMemorise = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
